import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import cors from "cors";
import { Provider } from "react-redux";
import { store, persistor } from "./store/Store";
import { PersistGate } from "redux-persist/integration/react";
import CurrencyProvider from "./context/CurrencyContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

cors({
  origin: "http://localhost:3000",
  methods: ["GET", "POST", "PUT"],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CurrencyProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </CurrencyProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
