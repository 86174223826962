import React, { memo, useEffect, useRef, useState } from "react";
import styles from "./DashboardInput.module.css";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ThemeProvider, createTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DTextInput = ({
  label,
  type,
  placeholder,
  value,
  endDate,
  customClassNameForInput,
  disabled,
  onChange,
  error = false,
  errorMsg,
  maxLength,
  customClassName,
  size,
  inr,
  infoMessage = "",
}) => {
  const theme = createTheme({
    typography: {
      htmlFontSize: 10,
      fontFamily: "gilroyMedium",
    },
  });
  //check if the selected endDate is today and hide previous time when its today
  const selectedDateStr = dayjs(endDate).format("YYYY-MM-DD");
  const todayStr = dayjs().format("YYYY-MM-DD");
  let isSameAsToday = selectedDateStr === todayStr;

  useEffect(() => {
    isSameAsToday = false;
  }, []);
  const ref = useRef(null);
  useEffect(() => {
    // ref?.current?.onChange((e) => e.preventDefault());
  });
  dayjs.extend(utc);
  const mergeEndDateAndTime = (endDateString, endTimeString) => {
    const endDate = dayjs(endDateString);
    const endTime = dayjs(endTimeString, "HH:mm:ss.SSSSSS");

    const mergedTime = endDate
      .hour(endTime.hour())
      .minute(endTime.minute())
      .second(endTime.second())
      .millisecond(endTime.millisecond());

    const utcMergedTime = mergedTime
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]");

    return utcMergedTime;
  };

  return (
    <div
      className={`${styles.d_input_container} ${
        disabled ? styles.disabledField : ""
      } ${customClassName}`}
      style={{ width: `${size}` }}
    >
      <label>{label}</label>
      <div className={`${styles.d_input_wrapper} ${error ? styles.error : ""}`}>
        {type !== "time" && (
          <input
            type={type || "text"}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            className={`${customClassNameForInput}`}
            placeholder={!placeholder ? `Enter ${label}` : placeholder}
            disabled={disabled}
          />
        )}

        {type === "time" && (
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                value={!value && isSameAsToday ? dayjs() : dayjs(value)}
                // value={dayjs(value)}
                onChange={(time, e) => {
                  const mergedTime = mergeEndDateAndTime(
                    endDate,

                    time?.format("HH:mm:ss.SSSSSS")
                  );
                  // const formattedTime = getFormattedTime(mergedTime);
                  if (isSameAsToday && time > dayjs()) {
                    // onChange(mergedTime);
                  }
                }}
                slotProps={{
                  textField: {
                    value: !value && isSameAsToday ? dayjs() : dayjs(value),
                    // value: dayjs(value),
                  },
                }}
                disablePast={isSameAsToday}
                disabled={disabled}
                onAccept={(time) => {
                  const mergedTime = mergeEndDateAndTime(
                    endDate,

                    time?.format("HH:mm:ss.SSSSSS")
                  );

                  if (isSameAsToday && time > dayjs()) {
                    onChange(mergedTime);
                  } else if (!isSameAsToday) {
                    onChange(mergedTime);
                  }
                }}
                sx={{
                  width: "100%",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
        )}
      </div>
      {infoMessage && (
        <div className={styles.infoMessageWrapper}>
          <InfoIcon sx={{ color: "#5b5d5b" }} />
          <p className={styles.infoMessage}>{infoMessage}</p>
        </div>
      )}
      {/* {inr && <p className={styles.show_count}>{inr}</p>} */}
      {error && <p className={styles.error_message}>{errorMsg}</p>}
    </div>
  );
};

export default memo(DTextInput);
